import { ContextForm as Form, useForm } from "@eitje/form";
import { Input } from "@eitje/form-fields-web";
import utils from "@eitje/web_utils";
import { login } from "actions/auth";
import { Button } from "antd";
import { DocsLink } from "components/ui";
import { t } from "initializers/i18n";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./styles/login.less";

const maxLengths = {
  api_key: 5,
  api_secret: 6,
};

const Login = () => {
  const [drawerV, setDrawerV] = useState(false);
  const { submit, setValues, getData, ...rest } = useForm();
  const data = getData();
  const { api_key, api_secret, env_id } = data;

  const [layoutName, setLayoutName] = useState("default");
  const [inputName, setInputName] = useState("api_key");

  const apiSecretInput = useRef();
  const envIdInput = useRef();

  const keyboard = useRef();

  useEffect(() => {
    keyboard.current?.setInput(data[inputName]);
  }, [api_key, api_secret, env_id]);

  const onChangeAll = (inputs) => {
    let current = inputs[inputName];
    if (inputName == "api_key" && current.length > 5) return; //apiSecretInput?.current?.focus();
    if (inputName == "api_secret" && current.length > 6) return; //envIdInput?.current?.focus();
    if (inputName == "env_id") current = current.replace(/[^0-9,.]/g, "");

    setValues({ [inputName]: current });
  };

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default";
    setLayoutName(newLayoutName);
  };

  const onKeyPress = (button) => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const maxLen = maxLengths[inputName];

  const isIE = !!window.document.documentMode;
  let condOpts = {};
  if (isIE) {
    condOpts["useMouseEvents"] = true;
  }

  return (
    <div className="login-page">
      <div className="center-page-inner">
        <div className="login-form">
          <div className="keys-row">
            <Input
              autoFocus
              maxLength={5}
              placeholder={null}
              onFocus={() => setInputName("api_key")}
              validate={(val) => validateLen(val, 5)}
              hideCharCounter
              required
              label="Key ID"
              field="api_key"
            />
            <Input
              maxLength={6}
              placeholder={null}
              onFocus={() => setInputName("api_secret")}
              validate={(val) => validateLen(val, 6)}
              hideCharCounter
              innerRef={apiSecretInput}
              label="Secret ID"
              field="api_secret"
            />
          </div>
          <Input
            onFocus={() => setInputName("env_id")}
            label="Environment ID"
            numOnly
            innerRef={envIdInput}
            disallowPattern={/[^0-9]/g}
            required
            placeholder="Environment ID"
            field="env_id"
          />
          <Button className="login-button" onClick={submit}>
            <img className="completed-icon" src="/icons/status/complete.png" />
            {t("submit")}
          </Button>

          <Keyboard
            maxLength={maxLen}
            onChangeAll={onChangeAll}
            inputName={inputName}
            layoutName={layoutName}
            onKeyPress={onKeyPress}
            {...condOpts}
            keyboardRef={(r) => (keyboard.current = r)}
          />

          <DocsLink
            className="find-keys-help-text"
            to="6037228-eitjeopdevloer-activeren#h_4e73a4fe74"
          >
            {t("findCodes")}
          </DocsLink>
        </div>
      </div>
    </div>
  );
};

const validateLen = (val, len) => {
  return {
    valid: val?.length == len,
    message: "minCount",
  };
};

const Wrapper = () => {
  const [cookies, setCookie] = useCookies(["eitje_vloer_serial_num"]);
  const { eitje_vloer_serial_num } = cookies;
  useEffect(() => {
    if (!eitje_vloer_serial_num)
      setCookie("eitje_vloer_serial_num", utils.randomId(), { path: "/" });
  }, []);

  const device_info = { serial_no: eitje_vloer_serial_num, name: "browser" };

  return (
    <Form
      transNamespace="login"
      onSubmit={(data) => login({ ...data, device_info })}
    >
      <Login />
    </Form>
  );
};

export default Wrapper;
